import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Icon,
    FormGroup,
    IconButton,
    InputAdornment,
    Box,
} from '@mui/material';
import Typography from "@mui/material/Typography";

const EditDialog = ({ open, onClose, mealList, onSave }) => {
    const [localMealList, setLocalMealList] = useState([...mealList]);

    useEffect(() => {
        setLocalMealList([...mealList]);
    }, [mealList]);

    const handleChangeCourse = (index) => (e) => {
        const updated = [...localMealList];
        updated[index].course = e.target.value;
        setLocalMealList(updated);
    };

    const handleChangeMeal = (courseIndex, mealKey) => (e) => {
        const updated = [...localMealList];
        updated[courseIndex].menu[mealKey] = e.target.value;
        setLocalMealList(updated);
    };

    const handleAddCourse = () => {
        setLocalMealList([...localMealList, { course: '', menu: {} }]);
    };

    const handleDeleteCourse = (index) => () => {
        const updated = [...localMealList];
        updated.splice(index, 1);
        setLocalMealList(updated);
    };

    const handleMoveCourseUp = (index) => () => {
        if (index === 0) return;
        const updated = [...localMealList];
        const temp = updated[index - 1];
        updated[index - 1] = updated[index];
        updated[index] = temp;
        setLocalMealList(updated);
    };

    const handleAddMeal = (index) => () => {
        const updated = [...localMealList];
        const menu = updated[index].menu || {};
        const nextKey = `option${Object.keys(menu).length}`;
        menu[nextKey] = '';
        updated[index].menu = menu;
        setLocalMealList(updated);
    };

    const handleDeleteMeal = (courseIndex, mealKey) => () => {
        const updated = [...localMealList];
        delete updated[courseIndex].menu[mealKey];
        setLocalMealList(updated);
    };

    const handleSave = () => {
        onSave(localMealList);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Menü bearbeiten</DialogTitle>
            <DialogContent>
                {localMealList.map((course, i) => (
                    <FormGroup key={`edit-course-${i}`} sx={{ mb: 4, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                        <Typography variant={"h5"} sx={{mb: 2}}>Gang {i+1}</Typography>
                        <TextField
                            label="Gang Beschreibung"
                            fullWidth
                            value={course.course}
                            onChange={handleChangeCourse(i)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleMoveCourseUp(i)} disabled={i === 0}>
                                            <Icon>arrow_upward</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleDeleteCourse(i)}>
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            sx={{ mb: 2 }}
                        />
                        {Object.entries(course.menu || {}).map(([mealKey, mealValue], j) => (
                            <TextField
                                key={`edit-meal-${j}`}
                                label={`Speise ${j + 1}`}
                                fullWidth
                                value={mealValue}
                                onChange={handleChangeMeal(i, mealKey)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleDeleteMeal(i, mealKey)}>
                                                <Icon color="error">delete</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{ mb: 1 }}
                            />
                        ))}
                        <Button onClick={handleAddMeal(i)} sx={{ mt: 1 }}>
                            Neue Speise hinzufügen <Icon>add</Icon>
                        </Button>
                    </FormGroup>
                ))}
                <Box sx={{display: 'flex', justifyContent :'center'}}><Button onClick={handleAddCourse} variant="outlined">
                    Neuen Gang hinzufügen <Icon>add</Icon>
                </Button></Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Abbrechen</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Speichern <Icon>save</Icon>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
