import React from "react";
import {Box, Container, Paper, Typography} from "@mui/material";
import Block from "../../components/Block";
import TimeBlock from "./TimeBlock";
import party from "../../assets/icons/club-party.svg";
import dinner from "../../assets/icons/food-dinner.svg";
import glasses from "../../assets/icons/glass-drinks.svg";
import cake from "../../assets/icons/wedding-cake.svg";
import reception from "../../assets/icons/wedding-reception-party.svg";
import rings from "../../assets/icons/wedding-rings.svg";
import castle from "../../assets/icons/castle.svg";

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const list = [
    {
        icon: castle,
        title: 'Einlass',
        time: '13:00 Uhr',
        text: 'Wir freuen uns euch ab 13 Uhr am Reisenberg begrüßen zu dürfen.',
    },
    {
        icon: reception,
        title: 'Zusammenkunft',
        time: '13:50 Uhr',
        text: 'Wir bitten alle Gäste bei Zeiten die Plätze einzunehmen.',
    },
    {
        icon: rings,
        title: 'Trauung',
        time: '14:00 Uhr',
        text: 'Wir möchten zu dieser Zeit mit der Trauungszeremonie beginnen.',
    },

    {
        icon: glasses,
        title: 'Agape',
        time: '~15:00 Uhr',
        text: 'Nach den Gratulationen werdet ihr, als unsere Gäste, mit einem Gläschen Sekt & Co., aber auch schmackhaften Snacks begrüßt.'
    },
    {
        icon: cake,
        title: 'Tortenanschnitt',
        time: '~16:00 Uhr',
        text: 'Eines der Highlights jeder Hochzeit ist bestimmt die Torte, zumindest sehen wir das so. Weshalb wir beschlossen haben diese nicht erst um Mitternacht zu präsentieren.'
    },
    {
        icon: dinner,
        title: 'Abendmenü',
        time: 'im Anschluss',
        text: 'Um 18:30 eröffnen wir gemeinsam ein herrliches Buffet.'
    },
    {
        icon: party,
        title: 'Tanz & Co.',
        time: 'ab ~ 21:00 Uhr',
        text: 'Nun möchten wir unseren ersten Tanz als verheiratetes Paar in romantischerer Atmosphäre aufs Parkett legen und euch danach mit lustigen Spielen, Musik und einer ausgelassener Party erheitern.'
    }
]


const Timeline = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return <Block>
        <Container maxWidth={"md"} sx={{
            maxHeight: t => `calc(100% - ${t.spacing(4)})`,
        }}>
            <Paper elevation={5}
                   sx={{
                       pt: 2,
                       paddingLeft: t => t.spacing(4),
                       paddingRight: t => t.spacing(4),
                       width: '100%',
                       overflow: "auto",
                       maxHeight: '100%',
                       boxSizing: 'border-box'
                   }}>
                <Typography variant={"h2"} component={"h1"}
                            sx={{marginBottom: 3, fontSize: {xs: '1.75rem', md: '2.25rem'}, textAlign: 'right', fontFamily: '"Darker Grotesque"'}}>TAGESABLAUF</Typography>

                <Box sx={{mb: '40px'}}><Typography variant={"h3"} sx={{marginBottom: 1, fontSize: {xs: '2.5rem', sm: '3rem'}}}>Unser Tag ♡</Typography></Box>
                { list.map((element, index) => (<TimeBlock isRight={index % 2 === 0  ? matches : true} icon={element.icon} time={element.time} text={element.text} title={element.title} />)) }
                <TimeBlock isRight={matches} endBlock/>
            </Paper>

        </Container>
    </Block>
}

export default Timeline;



/*
                <TimeBlock isRight={matches} icon={party} time={"21:30"} title={"Beginn der Party"}
                           text={"Ab jetzt beginnt die Party, nun sind dinge wie erster Tanz, kleine Spiele und gemüdliches bei sammen sitzen angesagt."}/>
                <TimeBlock isRight icon={drink} time={"23:30"} title={"Ende des Festes"}
                           text={"Ab 23:30 wird unser Caterer anfangen abzubauen und es werden keine Getränke mehr ausgeschenkt. Das heißt wir gehen davon aus dass das Fest bald vorbei sein wird. Mal schauen wie es sich Entwickelt."}/>

 */
