import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {
    Button, Box, LinearProgress
} from "@mui/material";
import {useAuth} from "../../components/AuthProvider";
import {CONFIG_REF, EMPTY_GUEST, EMPTY_MEAL, GUEST_LIST_REF} from "../../common/constants";
import {child, onValue, set} from "firebase/database";
import {useSnackbar} from 'notistack';
import Block from "../../components/Block";
import { hasPromise, missingNames} from "../../common/statelessFunctions";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";
import ParagraphHeader from "../../components/ParagraphHeader";
import PagePaper from "../../components/PagePaper";
import {MealPaper} from "../../components";

/*
const tempMealList = [
    {
        course: "Vorspeise",
        menu:
            {
                option1: "Paradeiser Hummus mit Falafel, Salatherz und Basilikumöl",
                option2: "Rosa Ötscherblick Schweinsrose mit grünem Spargel, Hokkaidokürbis und Zitronenmelisse",
                option3: "Roastbeef mit eingelegten Pilzen, Portweinzwiebel und süßer Senfsoße",
            }

    }, {
        course: "HAUPTGANG",
        menu:
            {
                option1: "Sous vide gegartes Landhendl mit Pilzgnocci und Fisolen",
                option2: "Ötscherblick Schweinsbackerl mit Eräpfel-Speck-Püree und Karotten- Zwiebel-Gemüsee",
                option3: "Knusprigen Zander aus Szegediner Kraut und Dillerdäpfel",
            }

    }, {
        course: "NACHSPEISE",
        menu:
            {
                option1: "Kleiner Schokobrownie",
                option2: "Punschkrapferl in der Mürbteigschale mit rosa Ribiselglasur",
                option3: "Passionsfrucht mit Mascarpone in weißer Schokolade",
            }

    }
]
*/

const Meal = () => {
    const {enqueueSnackbar} = useSnackbar();
    const {code, user} = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [guestData, setGuestData] = useState(EMPTY_GUEST);
    const [isRadio, setIsRadio] = useState(false);
    const [mealList, setMealList] = useState([]);

    useEffect(() => {
        if (code) {
            onValue(child(CONFIG_REF, 'meal'), snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                setIsRadio(data.isRadio);
                if(data.mealList) setMealList(data.mealList);
            })

            onValue(child(GUEST_LIST_REF, code), snap => {
                if (!snap.exists()) return;
                const data = snap.val();
                if (!data.meal) {
                    const nextGuestData = {...EMPTY_GUEST, meal: data.promiseNames.filter(d => d.dinner).map(g => ({...EMPTY_MEAL, name: g.fullName})), ...data}
                    setGuestData(nextGuestData);
                    set(child(GUEST_LIST_REF, `${data.key}/meal`), nextGuestData.meal).then();
                } else if (data.meal) {
                    const promise = data.promiseNames?.filter(d => d.dinner).map(p => p.fullName).filter(n => n?.trim() !== "") ?? [];
                    const meals = data.meal.map(m => promise.indexOf(m.name) > -1 ? m : null).filter(m => !!m);
                    if (meals.length === promise.length) { setGuestData({...data, meal: meals}); } else {
                        const nextMeals = promise.map(n => meals.find(m => m.name === n) ? meals.find(m => m.name === n) : {...EMPTY_MEAL, name: n});
                        setGuestData({...data, meal: nextMeals});
                        set(child(GUEST_LIST_REF, `${data.key}/meal`), nextMeals).then();
                    }
                }

                setIsLoading(false);

            })
        }
    }, [code]);

    const handleSelectionChange = (fullName, course) => (event) => {
        const { value } = event.target;
        const guestMeal = guestData.meal.find(m => m.name === fullName) || {};

        // Falls selectedCourses nicht existiert, initialisiere es mit leeren Arrays
        if (!guestMeal.selectedCourses) {
            guestMeal.selectedCourses = mealList.map(() => ['']);
        }

        // Logik für Radio vs. Checkbox
        guestMeal.selectedCourses[course] = isRadio
            ? [`${value}`]
            : guestMeal.selectedCourses[course].includes(value)
                ? guestMeal.selectedCourses[course].filter(c => c !== value)
                : [...guestMeal.selectedCourses[course], value];

        // Update des State mit der neuen Auswahl
        const meals = guestData.meal.map(m => m.name === fullName ? guestMeal : m);
        setGuestData({ ...guestData, meal: meals });
    };

    const getSelection = (fullName, course, value = null) => {
        const guestMeal = guestData.meal.find(m => m.name === fullName);

        if (!guestMeal?.selectedCourses) return value === null ? '' : false;

        return value === null
            ? guestMeal.selectedCourses[course]?.[0] || ''  // Für `getValue`
            : guestMeal.selectedCourses[course]?.includes(value);  // Für `isChecked`
    };

    const handleCheckChild = fullName => event => {
        const {checked, name} = event.target;
        let meals = guestData.meal.map(m => m.name === fullName ? {...m, [name]: checked} : m);
        if (name === "child" && !checked) meals = meals.map(m => m.name === fullName ? {...m, desert: true} : m);
        setGuestData({...guestData, meal: meals});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await set(child(GUEST_LIST_REF, `${guestData.key}/meal`), guestData.meal)
            enqueueSnackbar('Gespeichert und abgeschickt. Wir sehen nun, was du siehst.', {
                variant: 'success',
            })
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }
    console.log(1,guestData)
    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"MENÜAUSWAHL"}/>
            <HeaderTwoNoImage title={`${user.displayName},`}/>
            <Paragraph>
                wir möchten {guestData.invitations > 1 ? 'euch' : 'dir'} unser Speiseangebot für unseren besonderen Tag vorstellen.
            </Paragraph>
            <Paragraph>
                Nach der Trauung wird es eine Agape geben. Um circa 19 uhr planen wir mit dem Abendessen zu starten.
                Damit wir ein genauer planen können und weniger Lebensmittel verschwenden, bitten anzugeben welche Speisen eher bevorzugt werden.
                Es darf dann natürlich von allem was genommen werden.
            </Paragraph>
            <Paragraph>
                Bei Unverträglichkeiten meldet euch bitte persönlich bei uns.
            </Paragraph>
            <ParagraphHeader title={"Speisekarte"}/>
            {isLoading && <LinearProgress/>}
            {!isLoading && hasPromise(guestData) !== 2 && <Box>
                <Paragraph important>
                    Bitte noch zu oder absagen!
                </Paragraph>
            </Box>}

            {!isLoading && hasPromise(guestData) === 2 && missingNames(guestData) && <Box>
                <Paragraph important>
                    Leider besitzt einer der Tischkarten zu dieser Einladung noch keinen Namen,
                    bitte den Namen ausfüllen oder bei einer Absage die Tischkarte löschen. <br/>
                    <Link to={`/promise`}>Einladung</Link>
                </Paragraph>
            </Box>}

            {!isLoading && mealList.length === 0 &&
                <MealPaper
                    mealList={mealList}
                    guestName={"Names es Gastes"}
                    isRadio={isRadio}
                />
            }

            {!isLoading && mealList.length > 0 && guestData?.meal && hasPromise(guestData) === 2 && !missingNames(guestData) &&
                <Box component={"form"} noValidate autoComplete="on" onSubmit={handleSubmit}>
                    {guestData.meal.map((m, z) => (
                        <MealPaper
                            key={z}
                            guestName={m.name}
                            isRadio={isRadio}
                            mealList={mealList}
                            getSelection={getSelection}
                            handleCheckChild={handleCheckChild}
                            handleSelectionChange={handleSelectionChange}
                            isChild={m.child}
                            needChair={m.chair}
                        />
                    ))}
                    <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 16, marginTop: 16}}>
                        <Button sx={{minWidth: 160}} variant={"outlined"} color={"primary"} type={"submit"}>Daten Speichern und Senden</Button>
                    </div>
                </Box>
            }


        </PagePaper>
    </Block>;
}

export default Meal;


