import Typography from "@mui/material/Typography";
import {FormControl, FormLabel, Paper, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import React from "react";
import Paragraph from "../Paragraph";
import ParagraphHeader from "../ParagraphHeader";import PropTypes from 'prop-types';

const MealPaper = ({mealList, guestName, isRadio, isChild, needChair, handleEdit, getSelection, handleSelectionChange, handleCheckChild}) =>{

    const disabeld = !getSelection || !handleSelectionChange || !handleCheckChild
    if(mealList.length === 0) return <Paper elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
        <ParagraphHeader title={"Das Menü ist (bald) servierbereit… 🍽️"} />
        <Paragraph>
            Wir tüfteln noch am perfekten Hochzeitsmenü – schließlich soll es nicht nur lecker, sondern auch ein kleines Highlight des Tages werden!
            Noch ist alles in Vorbereitung, aber keine Sorge: Schon bald gibt’s hier alle köstlichen Details.
        </Paragraph>

        <Paragraph>
            Schaut doch einfach im nächsten oder übernächsten Monat nochmal vorbei – dann verraten wir, was auf die Teller kommt. 💕
            Wir freuen uns schon riesig darauf, euch verwöhnen zu dürfen!
        </Paragraph>
        { handleEdit && <FormGroup>
            <Button variant="contained" color="primary" onClick={handleEdit}>Bearbeiten <Icon>edit</Icon></Button>
        </FormGroup> }
    </Paper>

    if(disabeld) return <Paper elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
        <ParagraphHeader title={guestName}/>
        {mealList.length > 0 && mealList.map((object, course) => (
            <FormControl fullWidth key={course}>
                <FormLabel id="demo-radio-buttons-group-label">{object.course}</FormLabel>
                {isRadio ?
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                    >
                        <FormControlLabel
                            value=""
                            sx={{py: 2}}
                            disabled
                            control={
                                <Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>
                            }
                            label="Keine Auswahl getroffen"/>
                        {Object.keys(object?.menu ?? {}).map((option, j) => (
                            <FormControlLabel
                                key={course+j}
                                value={option}
                                sx={{py: 1}}
                                control={
                                    <Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>
                                }
                                label={object.menu[option]}
                            />
                        ))}
                    </RadioGroup>:
                    <FormGroup>
                        {Object.keys(object?.menu ?? {}).map((meal, j) => (
                            <FormControlLabel
                                key={course+j}
                                value={meal}
                                sx={{py: 1}}
                                control={
                                    <Checkbox
                                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                        disabled
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={object.menu[meal]}
                            />
                        ))}
                    </FormGroup>
                }
            </FormControl>
        ))}
        <FormGroup>
            <FormLabel>Ich bin ein KIND / BABY</FormLabel>
            <FormControlLabel sx={{color: '#333'}}
                              control={<Checkbox name={"child"} disabled/>}
                              label="Ja"
                              labelPlacement="end"/>
            <FormControlLabel sx={{color: '#333'}}
                              control={<Checkbox name={"chair"} disabled/>}
                              label="Ich benötige einen Hochstuhl."
                              labelPlacement="end"/>
        </FormGroup>

        <Typography variant={"p"} sx={{display: 'block', py: 2, textAlign: 'left', fontSize: '1rem'}}>
            Diese Person kommt doch nicht zur Hochzeit oder kann nicht bis zum Abendessen bleiben ?
            Dann bitte dies in der <Link to={`/promise`}>Einladung</Link> zu ändern.
        </Typography>
        { handleEdit && <FormGroup>
            <Button variant="contained" color="primary" onClick={handleEdit}>Bearbeiten <Icon>edit</Icon></Button>
        </FormGroup> }
    </Paper>

    return <Paper elevation={2} sx={{p: 2, boxSizing: 'border-box', marginTop: 1, marginBottom: 2}}>
        <ParagraphHeader title={guestName}/>
        {mealList.length > 0 && mealList.map((object, course) => (
            <FormControl fullWidth key={course}>
                <FormLabel id="demo-radio-buttons-group-label">{object.course}</FormLabel>
                {isRadio ?
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        value={getSelection (guestName, course)}
                        onChange={handleSelectionChange(guestName, course)}
                    >
                        <FormControlLabel
                            value=""
                            sx={{py: 1}}
                            control={
                                <Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>
                            }
                            label="Keine Auswahl getroffen"/>
                        {Object.keys(object?.menu ?? {}).map((option, j) => (
                            <FormControlLabel
                                key={course+j}
                                value={option}
                                sx={{py: 1}}
                                control={
                                    <Radio sx={{'& .MuiSvgIcon-root': {fontSize: 28}}} />
                                }
                                label={object.menu[option]}
                            />
                        ))}
                    </RadioGroup>:
                    <FormGroup>
                        {Object.keys(object?.menu ?? {}).map((meal, j) => (
                            <FormControlLabel
                                key={course+j}
                                value={meal}
                                sx={{py: 1}}
                                control={
                                    <Checkbox
                                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                        checked={getSelection(guestName, course, meal)}
                                        onChange={handleSelectionChange(guestName, course)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={object.menu[meal]}
                            />
                        ))}
                    </FormGroup>
                }
            </FormControl>
        ))}
        <FormGroup>
            <FormLabel>Ich bin ein KIND / BABY</FormLabel>
            <FormControlLabel sx={{color: '#333'}}
                              control={<Checkbox name={"child"} checked={isChild} onClick={handleCheckChild(guestName)}/>}
                              label="Ja"
                              labelPlacement="end"/>
            {isChild && <FormControlLabel sx={{color: '#333'}}
                              control={<Checkbox name={"chair"} checked={needChair} onClick={handleCheckChild(guestName)}/>}
                              label="Ich benötige einen Hochstuhl."
                              labelPlacement="end"/> }
        </FormGroup>

        <Typography variant={"p"} sx={{display: 'block', py: 2, textAlign: 'left', fontSize: '1rem'}}>
            Diese Person kommt doch nicht zur Hochzeit oder kann nicht bis zum Abendessen bleiben ?
            Dann bitte dies in der <Link to={`/promise`}>Einladung</Link> zu ändern.
        </Typography>
        { handleEdit && <FormGroup>
            <Button variant="contained" color="primary" onClick={handleEdit}>Bearbeiten <Icon>edit</Icon></Button>
        </FormGroup> }
    </Paper>
}

MealPaper.propTypes = {
    mealList: PropTypes.arrayOf(
        PropTypes.shape({
            course: PropTypes.string.isRequired,
            menu: PropTypes.objectOf(PropTypes.string).isRequired,
        })
    ).isRequired,
    guestName: PropTypes.string.isRequired,
    isRadio: PropTypes.bool.isRequired,
    isChild: PropTypes.bool,
    needChair: PropTypes.bool,
    handleEdit: PropTypes.func,
    getSelection: PropTypes.func,
    handleSelectionChange: PropTypes.func,
    handleCheckChild: PropTypes.func,
};


export default MealPaper;
