// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDK1r-Z8fXKFZs4ZbywSecHrGXeH9cqWHU",
    authDomain: "vermaehlung-basis.firebaseapp.com",
    databaseURL: "https://vermaehlung-basis-muj.europe-west1.firebasedatabase.app",
    projectId: "vermaehlung-basis",
    storageBucket: "vermaehlung-basis.appspot.com",
    messagingSenderId: "1078027458506",
    appId: "1:1078027458506:web:d1f83a3c5084b0a43b2133",
    measurementId: "G-R714NZGQ43"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebase);
