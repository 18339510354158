import React, {useCallback, useEffect, useState} from "react";
import {
    Switch
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {child, onValue, set} from "firebase/database";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { CONFIG_REF} from "../../common/constants";
import {useSnackbar} from "notistack";
import Block from "../../components/Block";
import HeaderOneNoImage from "../../components/HeaderOneNoImage";
import PagePaper from "../../components/PagePaper";
import HeaderTwoNoImage from "../../components/HeaderTwoNoImage";
import Paragraph from "../../components/Paragraph";
import {MealEditDialog, MealPaper} from "../../components";

const defaultText = "Nach der Trauung wird es eine Agape geben. Um circa 19 uhr planen wir mit dem Abendessen zu starten.\n Damit wir ein genauer planen können und weniger Lebensmittel verschwenden, bitten anzugeben welche Speisen eher bevorzugt werden.\n Es darf dann natürlich von allem was genommen werden."


const Config = () => {
    const {enqueueSnackbar} = useSnackbar();

    //const [data, setData] = useState([])
    const [isRadio, setIsRadio] = useState(true);
    const [isShown, setIsShown] = useState(true);
    const [isUsedAsFeedback, setIsUsedAsFeedback] = useState(true);
    const [mealList, setMealList] = useState([]);
    const [editOpen, setEditOpen] = useState(false);

    const setDefaultMeal = useCallback(() => {
        set(child(CONFIG_REF, `meal`), {
            isShown: true,
            isUsedAsFeedback: true,
            isRadio: true,
            mealList: []
        }).then(() => {
            enqueueSnackbar('Daten einmalig Initialisiert', {
                variant: 'success',
            })
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern', {
                variant: 'error',
            })
        });
    }, [enqueueSnackbar])

    useEffect(() => {
        const unsub = onValue(CONFIG_REF, snap => {
            if (!snap.exists()) return setDefaultMeal()
            const data = snap.val();
            if (data.meal == null) setDefaultMeal()
            //setData(data);
            setIsRadio(data.meal.isRadio);
            setIsShown(data.meal.isShown);
            setIsUsedAsFeedback(data.meal.isUsedAsFeedback);
            setMealList(data.meal.mealList ?? [])
        })
        return () => {
            unsub();
        }
    }, [setDefaultMeal])

    const handleSaveEdit = (updatedList) => {
        set(child(CONFIG_REF, 'meal/mealList'), updatedList)
        .then(() => enqueueSnackbar('Menü aktualisiert', { variant: 'success' }))
        .catch((err) => enqueueSnackbar('Fehler beim Speichern', { variant: 'error' }));
    };

    const saveToggleRadio = async (e) => {
        try {
            await set(child(CONFIG_REF, `meal/isRadio`), !isRadio)
            enqueueSnackbar('Die änderungen wurden Gespeichert', {
                variant: 'success',
            })
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }

    return <Block>
        <PagePaper>
            <HeaderOneNoImage title={"Einstellungen"}/>
            <HeaderTwoNoImage title={"Eure Menu Einstellungen"}/>
            <Paragraph>
                Hier könnt ihr festlegen, ob ihr die Menüseite anzeigen möchtet oder nicht.
            </Paragraph>
            <Paragraph>
                Außerdem wählt ihr aus, wie viele Gänge es geben wird – und was Leckeres es bei jedem Gang zu essen gibt. 🍽️
            </Paragraph>
            <Paragraph>
                Möchtet ihr, dass eure Gäste ihr Menü selbst auswählen oder es einfach nur ansehen können? Auch das könnt ihr hier entscheiden.
            </Paragraph>
            <Paragraph>
                Und zum Schluss: Soll pro Gang nur eine Speise gewählt werden dürfen – oder lieber mehrere? Ihr habt die Wahl! 💕
            </Paragraph>
            <FormGroup>
                <FormControlLabel control={<Switch checked={isShown} disabled/>} label="Soll die Seite angezeigt werden ?"/>
                <FormControlLabel control={<Switch checked={isUsedAsFeedback} disabled/>} label="Soll es eine Umfrage für die Speisen geben?"/>
                <FormControlLabel control={<Switch checked={!isRadio} onChange={saveToggleRadio}/>} label="Darf pro Gang mehrere Speißen gewählt werden?"/>
                <TextField
                    id="standard-multiline-flexible"
                    label="Einleitungstext für die Seite."
                    defaultValue={defaultText}
                    multiline
                    minRows={3}
                    maxRows={25}
                    variant="outlined"
                    disabled
                    sx={{my: 2}}
                />
            </FormGroup>
            <Paragraph>Unserer angebotenen Speisen:</Paragraph>
            <MealPaper
                mealList={mealList}
                isRadio={isRadio}
                guestName={"Name des Gastes"}
                handleEdit={() => setEditOpen(true)}
            />
        </PagePaper>
        <MealEditDialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            mealList={mealList}
            onSave={handleSaveEdit}
        />
    </Block>
}

export default Config;
