import React from "react";
import {Box, Link as HtmlLink} from "@mui/material";
import mainPic from '../../assets/images/amreisenberg-hochzeitslocation.webp';
import Block from "../../components/Block";
import Typography from "@mui/material/Typography";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import PagePaper from "../../components/PagePaper";
import PageImage from "../../components/PageImage";
import HeaderOneImage from "../../components/HeaderOneImage";
import HeaderTwoImage from "../../components/HeaderTwoImage";
import Paragraph from "../../components/Paragraph";
import HeaderThreeImage from "../../components/HeaderThreeImage";

const containerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
};

const center = {
    lat: 48.260059,
    lng: 16.331372
};

const parkplatz1 = {
    lat: 48.259642,
    lng: 16.335532
};

const parkplatz2 = {
    lat: 48.263955,
    lng: 16.321936
};


const MyLink = ({href, label}) => (<HtmlLink
    sx={{
        fontWeight: 'bold',
        color: t => t.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }}
    target={"_blank"}
    href={href}
    rel={"noreferrer"}
>{label}</HtmlLink>)

const Location = () => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAbM-tpvMdDr-0x2q7pAnQZ7o3lqFL4h3s"
    })

    return <Block>
        <PagePaper withImage>
                    <PageImage alt={"Schloss Hof"} src={mainPic}/>
                    {/* ----- ABSATZ 1 ----- */}
                    <Box sx={{px: 4}}>
                        <HeaderOneImage title={"Am Reisenberg,"}/>
                        <HeaderTwoImage title={"Oberer Reisenbergweg 15 1190 Wien"}/>
                        <Paragraph>
                            Liebe Freunde und Familie,
                        </Paragraph>
                        <Paragraph>
                            wir freuen uns euch nun zum Ort des Geschehens, wo wir uns das JA-Wort geben werden, einladen zu können.
                            Gemeinsam mit euch wollen wir im <b>Weingut am Reisenberg</b> unsere Hochezeit am <b style={{whiteSpace: 'nowrap'}}>09.08.2024</b> feiern.
                        </Paragraph>


                        {/* ----- TRAUUNG ----- */}
                        <HeaderThreeImage title={"TRAUUNG"}/>
                        <Paragraph inset>
                            Um <b>14 Uhr</b> werden wird die Trauung, wenn das Wetter mitspielt im Garten mit Ausblick über Wien statt finden.
                        </Paragraph>
                        <Paragraph inset>
                            Wir bitten euch jedoch spätestens eine halbe Stunde vor der Trauung vor Ort zu sein.
                        </Paragraph>

                        {/* ----- DRESSCODE ----- */}
                        <HeaderThreeImage title={"DRESSCODE"}/>
                        <Paragraph inset>
                            Um sommerlich festliche Kleidung in gedeckten Farben wird gebeten.
                        </Paragraph>
                        <Paragraph inset>
                            Wir empfehlen euch ggf. Sonnenhüte, Sonnenbrillen und Sonnencreme mitzunehmen, um den warmen Temperaturen
                            im August stand zu halten.
                        </Paragraph>


                        {/* ----- ANREISE ----- */}
                        <HeaderThreeImage title={"ANREISE & PARKPLATZ"}/>

                        <Typography
                            variant={"p"}
                            component={"p"}
                            sx={{ml: 1, mb: 1, mt: -2, fontSize: '1rem', textAlign: 'left', fontStyle: "italic", fontFamily: '"Darker Grotesque"'}}
                        >
                        </Typography>
                        <Paragraph inset>
                            Bei der Location gibt es leider nicht ausreichend Parkplätze. Daher bitten wir euch, mit dem Auto entweder unten in der Cobenzelgasse – bevor man links das letzte Stück hinauffahren kann – oder am Parkplatz beim Schloss Cobenzl zu parken.
                        </Paragraph>
                        <Paragraph inset>
                            Die Anreise mit öffentlichen Verkehrsmittel ist möglich und wird unten auf der Website beschrieben.
                            <MyLink href={"https://amreisenberg.at/"} label={"Anreisemöglichkeiten"}/>
                        </Paragraph>
                    </Box>
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={16}
                            options={{gestureHandling: 'cooperative'}}
                        >
                            <Marker
                                position={parkplatz1}
                                label={"Parken in der Cobenzelgasse"}
                            />
                            <Marker
                                position={parkplatz2}
                                label={"Parkplatz Schloss Cobenzel"}
                            />
                        </GoogleMap>
                    ) : <></>}

                </PagePaper>
            </Block>
}

export default Location;
