import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    FormGroup,
    Icon,
    IconButton,
    Box,
    Divider
} from '@mui/material';
import {CONFIG_REF, EMPTY_GUEST, GUEST_LIST_REF} from '../../common/constants';
import {hasPromise, missingNames} from "../../common/statelessFunctions";
import {MealPaper} from "../index";
import {child, onValue, set} from "firebase/database";
import {useSnackbar} from "notistack";

const GuestEditDialog = ({
                             open,
                             onClose,
                             onSubmit,
                             guestData,
                             errorMessage,
                             handleChange,
                             handleChangeGuest,
                             handleRemove,
                             addPersonToList
                         }) => {
    const {enqueueSnackbar} = useSnackbar();
    const [isRadio, setIsRadio] = useState(true);
    //const [isShown, setIsShown] = useState(true);
    //const [isUsedAsFeedback, setIsUsedAsFeedback] = useState(true);
    const [mealList, setMealList] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [guest, setGuest] = useState(EMPTY_GUEST);

    useEffect(() => {
        setGuest(guestData)
    }, [guestData]);

    useEffect(() => {
        onValue(CONFIG_REF, snap => {
            if (!snap.exists()) return
            const data = snap.val();
            setIsRadio(data.meal.isRadio);
            //setIsShown(data.meal.isShown);
            //setIsUsedAsFeedback(data.meal.isUsedAsFeedback);
            setMealList(data.meal.mealList ?? [])
        })
    }, [])

    const handleSubmitMeal = async (e) => {
        e.preventDefault();
        try {
            await set(child(GUEST_LIST_REF, `${guest.key}/meal`), guest.meal)
            enqueueSnackbar('Gespeichert und abgeschickt. Wir sehen nun, was du siehst.', {
                variant: 'success',
            })
            setEditOpen(false);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            enqueueSnackbar('Fehler beim Speichern und Senden', {
                variant: 'error',
            })
        }
    }

    const handleSelectionChange = (fullName, course) => (event) => {
        const { value } = event.target;
        const guestMeal = guest.meal.find(m => m.name === fullName) || {};

        // Falls selectedCourses nicht existiert, initialisiere es mit leeren Arrays
        if (!guestMeal.selectedCourses) {
            guestMeal.selectedCourses = mealList.map(() => ['']);
        }

        // Logik für Radio vs. Checkbox
        guestMeal.selectedCourses[course] = isRadio
            ? [`${value}`]
            : guestMeal.selectedCourses[course].includes(value)
                ? guestMeal.selectedCourses[course].filter(c => c !== value)
                : [...guestMeal.selectedCourses[course], value];

        // Update des State mit der neuen Auswahl
        const meals = guest.meal.map(m => m.name === fullName ? guestMeal : m);
        setGuest({ ...guest, meal: meals });
    };

    const getSelection = (fullName, course, value = null) => {
        const guestMeal = guest.meal.find(m => m.name === fullName);

        if (!guestMeal?.selectedCourses) return value === null ? '' : false;

        return value === null
            ? guestMeal.selectedCourses[course]?.[0] || ''  // Für `getValue`
            : guestMeal.selectedCourses[course]?.includes(value);  // Für `isChecked`
    };

    const handleCheckChild = fullName => event => {
        const {checked, name} = event.target;
        let meals = guest.meal.map(m => m.name === fullName ? {...m, [name]: checked} : m);
        if (name === "child" && !checked) meals = meals.map(m => m.name === fullName ? {...m, desert: true} : m);
        setGuest({...guest, meal: meals});
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create / Edit - Gast</DialogTitle>
            <Box component="form" noValidate autoComplete="on" onSubmit={onSubmit}>
                <DialogContent>
                    <TextField
                        id="name"
                        label="Name des Gast / der Gäste"
                        type="text"
                        name="name"
                        value={guest.name}
                        onChange={handleChange}
                        autoComplete="name"
                        variant="standard"
                        size="medium"
                        autoFocus
                        fullWidth
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                    <TextField
                        id="mail"
                        label="E-Mail des Gast / der Gäste"
                        type="text"
                        name="mail"
                        value={guest.mail}
                        onChange={handleChange}
                        autoComplete="mail"
                        variant="standard"
                        size="medium"
                        fullWidth
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                    <TextField
                        id="count"
                        label="Anzahl Eingeladene Personen"
                        type="number"
                        name="invitations"
                        value={guest.invitations}
                        onChange={handleChange}
                        autoComplete="count"
                        variant="standard"
                        size="medium"
                        fullWidth
                        error={!!errorMessage}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-site-label">Eingeladen von</InputLabel>
                        <Select
                            labelId="select-site-label"
                            id="select-site"
                            value={guest.site}
                            name="site"
                            onChange={handleChange}
                            label="site"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Marlene">Marlene</MenuItem>
                            <MenuItem value="Johannes">Johannes</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="refusal"
                                color="error"
                                checked={guest.refusal}
                                onChange={handleChange}
                            />
                        }
                        label="Abgesagt"
                    />
                    {!guest.refusal && guest.promiseNames.map((person, i) => (
                        <FormGroup key={i} sx={{padding: 1, boxSizing: 'border-box', marginTop: 1}}>
                            <TextField
                                id={`fullName-${i}`}
                                label={`Name für Tischkarte, Gast ${i + 1}`}
                                type="text"
                                name="fullName"
                                value={person.fullName}
                                onChange={handleChangeGuest(i)}
                                autoComplete="fullName"
                                variant="standard"
                                size="medium"
                                fullWidth
                            />
                            <FormControlLabel control={<Checkbox name="marriage" checked={person.marriage} onChange={handleChangeGuest(i)} />} label="Trauung" />
                            <FormControlLabel control={<Checkbox name="agape" checked={person.agape} onChange={handleChangeGuest(i)} />} label="Agape" />
                            <FormControlLabel control={<Checkbox name="dinner" checked={person.dinner} onChange={handleChangeGuest(i)} />} label="Essen" />
                            <FormControlLabel control={<Checkbox name="party" checked={person.party} onChange={handleChangeGuest(i)} />} label="Party" />
                            <Divider />
                            <FormControlLabel control={<Checkbox name="maybe" checked={person.maybe} onChange={handleChangeGuest(i)} />} label="Vielleicht" />
                            <Button variant="text" onClick={() => handleRemove(i)}>Löschen</Button>
                        </FormGroup>
                    ))}
                    {guest.promiseNames.length < guest.invitations && (
                        <IconButton disabled={guest.refusal} onClick={addPersonToList}>
                            <Icon>add</Icon>
                        </IconButton>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="info" onClick={() => setEditOpen(true)} sx={{mr: 3}}>Speisen Betrachten</Button>
                    <Button variant="text" color="neutral" onClick={onClose}>abbrechen</Button>
                    <Button variant="text" color="primary" type="submit">speichern</Button>
                </DialogActions>
            </Box>

            <Dialog open={editOpen}>
                <DialogTitle>Edit Gewählte Speiden</DialogTitle>
                <DialogContent>
                    {mealList.length > 0 && guest?.meal && hasPromise(guest) === 2 && !missingNames(guest) &&
                        <Box component={"form"} noValidate autoComplete="on" onSubmit={handleSubmitMeal}>
                            {guest.meal.map((m, z) => (
                                <MealPaper
                                    key={z}
                                    guestName={m.name}
                                    isRadio={isRadio}
                                    mealList={mealList}
                                    getSelection={getSelection}
                                    handleCheckChild={handleCheckChild}
                                    handleSelectionChange={handleSelectionChange}
                                    isChild={m.child}
                                    needChair={m.chair}
                                />
                            ))}
                            <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', gap: 16, marginTop: 16}}>
                                <Button variant="text" color="neutral" onClick={() => setEditOpen(false)}>abbrechen</Button>
                                <Button sx={{minWidth: 160}} variant={"text"} color={"primary"} type={"submit"}>Daten Speichern und Senden</Button>
                            </div>
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </Dialog>
    );
};

GuestEditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    guestData: PropTypes.shape({
        name: PropTypes.string,
        mail: PropTypes.string,
        site: PropTypes.string,
        invitations: PropTypes.number,
        refusal: PropTypes.bool,
        promiseNames: PropTypes.arrayOf(PropTypes.shape({
            fullName: PropTypes.string,
            marriage: PropTypes.bool,
            agape: PropTypes.bool,
            dinner: PropTypes.bool,
            party: PropTypes.bool,
            maybe: PropTypes.bool,
        })),
        meal: PropTypes.array,
    }).isRequired,
    errorMessage: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleChangeGuest: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    addPersonToList: PropTypes.func.isRequired,
};

export default GuestEditDialog;
